<template>
  <div class="payments"  v-if="hasPerm('payments.view_payment')">
    <page-header title="Paiements" icon="fa fa-euro" :links="getLinks()"></page-header>
    <div v-show="initialLoadingDone">
      <b-row>
        <b-col>
          <h2>
            <counter-label :counter="paymentsCount" label="paiement" v-if="!isLoading(loadingName)"></counter-label>
            <span v-if="isLoading(loadingName)">--</span>
            <span v-if="!isLoading(loadingName) && pagesCount === 1">
              pour un total de {{ totalAmount | currency }}
            </span>
          </h2>
        </b-col>
      </b-row>
      <div class="filter-block">
        <div class="filter-line">
          <b-row>
            <b-col cols="2">
              <b-form-group
                id="payment-modes-group"
                label="Mode de paiement"
                label-for="payment-modes"
              >
                <b-form-select
                  id="payment-modes"
                  v-model="selectedPaymentMode"
                  >
                  <b-form-select-option
                    :value="item"
                    v-for="item in paymentModes"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group
                id="status-group"
                label="État des factures"
                label-for="status"
              >
                <b-form-select
                  id="status"
                  v-model="selectedStatus"
                  >
                  <b-form-select-option
                    :value="item"
                    v-for="item in invoicesStatus"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group
                id="verified-group"
                label="Vérification"
                label-for="verified"
              >
                <b-form-select
                  id="verified"
                  v-model="selectedVerified"
                  >
                  <b-form-select-option
                    :value="item"
                    v-for="item in verifiedStatus"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group
                id="deposits-group"
                label="Dépôt"
                label-for="deposits"
              >
                <b-form-select
                  id="deposits"
                  v-model="selectedDepositMode"
                  >
                  <b-form-select-option
                    :value="item"
                    v-for="item in depositModes"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group
                id="sorting-group"
                label="Trier par"
                label-for="sorting"
              >
                <b-form-select
                  id="v"
                  v-model="selectedSortBy"
                  >
                  <b-form-select-option
                    :value="item"
                    v-for="item in sortBy"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </b-form-select-option>
                </b-form-select>
                <b-form-checkbox v-model="sortReversed" class="small-text">
                  {{ reversedLabel }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="2" class="text-right">
              <b-checkbox
                :checked="noPagination"
                :disabled="noPaginationDisabled"
                @change="reloadPagination"
                style="margin-bottom: 5px"
              >
                Tout voir
              </b-checkbox>
              <pagination
                :pages-count="pagesCount"
                :has-next="hasNext"
                :has-previous="hasPrevious"
                :selector="paginationSelector"
                @change="onPageChanged($event)"
                :disabled="isLoading(loadingName) && pagesCount === 1"
                small
                css-style="justify-content: flex-end;"
              >
              </pagination>
            </b-col>
          </b-row>
        </div>
        <div class="filter-line">
          <b-row>
            <b-col cols="2">
              <b-form-group
                id="payment-date-group"
                label="Date"
                label-for="payment-date"
              >
                <b-form-select
                  id="payment-date"
                  v-model="selectedDate"
                  :disabled="byCreationDate"
                  @change="onSelectedDateChanged"
                >
                  <b-form-select-option
                    :value="item.value"
                    v-for="item in dates"
                    :key="item.value"
                  >
                    {{ item.getLabel() }}
                  </b-form-select-option>
                </b-form-select>
                <b-form-checkbox v-model="byCreationDate" class="small-text">
                  Date de saisie
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <date-frame-selector
                :start-date="startDate"
                :end-date="endDate"
                :disabled="selectedDate !== ''"
                @change="onDateFrameChanged"
              >
              </date-frame-selector>
            </b-col>
            <b-col cols="2">
              <b-form-group
                id="bank-number-group"
                label="N° de chèque"
                label-for="bank-number"
              >
                <b-input-group>
                  <b-form-input
                    id="bank-number"
                    name="bank-number"
                    type="text"
                    v-model="bankNumber"
                    @change="onBankNumberChanged(bankNumber)"
                  >
                  </b-form-input>
                  <b-input-group-append>
                    <b-button
                      :disabled="!bankNumber"
                      variant="secondary"
                      @click.prevent="onBankNumberChanged('')"
                    >
                      <i class="fa fa-eraser"></i>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="1">
              <br />
              <b-form-checkbox v-model="returned" id="returned" @change="onFilterChanged()">
                <b>Chèques rendus</b>
              </b-form-checkbox>
            </b-col>
            <b-col cols="3">
              <b-form-group
                id="entity-group"
                label="Famille"
                label-for="entity-name"
                description="Nom ou numéro"
              >
                <b-input-group>
                  <b-form-input
                    id="entity-name"
                    name="entity-name"
                    type="text"
                    v-model="entityFilter"
                    @change="onEntityFilterChanged(entityFilter)"
                  >
                  </b-form-input>
                  <b-input-group-append>
                    <b-button
                      :disabled="!entityFilter"
                      variant="secondary"
                      @click.prevent="onEntityFilterChanged('')"
                    >
                      <i class="fa fa-eraser"></i>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div v-if="analytics.length">
          <b-row>
            <b-col>
              <a class="header-link" href @click.prevent="filterByAnalytic = !filterByAnalytic">
                Filtrer par code analytique
              </a>
            </b-col>
            <b-col cols="3">
              <b-input
                type="text"
                v-model="analyticFilter"
                v-if="filterByAnalytic"
                placeholder="Entrez le nom d'un analytique"
              >
              </b-input>
            </b-col>
          </b-row>
          <div v-if="filterByAnalytic">
            <b-row>
              <b-col>
                <check-box-select
                  id="analytic-select"
                  class="analytics-selector no-print"
                  :choices="analyticChoices"
                  inline
                  @changed="selectedAnalyticsChanged($event)"
                ></check-box-select>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div class="header-line" v-if="canChangePayment">
        <b-row>
          <b-col cols="7">
            <div class="header-label">
              <counter-label
                :counter="verifiedPayments.size"
                label="paiement sélectionné pour vérification"
                label-n="paiements sélectionnés pour vérification"
              >
              </counter-label>
              <div class="total-selection" v-if="verifiedPayments.size">Total de {{ verifiedAmount }}</div>
            </div>
          </b-col>
          <b-col cols="2" class="text-right">
            <a class="btn btn-secondary btn-block" @click.prevent="selectAllForVerification()"
               v-if="verifiablePayments.length"
            >
              <span v-if="verifiedPayments.size === verifiablePayments.length">Tout désélectionner</span>
              <span v-else>Tout sélectionner</span>
            </a>
          </b-col>
          <b-col cols="3" class="text-right">
            <a class="btn btn-primary" @click.prevent="onConfirmVerification()"
               :class="{ disabled: verifiedPayments.size === 0}"
            >
              <i class="fa fa-check-square"></i> Marquer comme vérifié
            </a>
          </b-col>
        </b-row>
      </div>
      <div class="header-line" v-if="!isDepositDisabled && canChangePayment">
        <b-row>
          <b-col cols="5">
            <div class="header-label">
              <counter-label
                :counter="depositSelection.length"
                label="paiement sélectionné pour un dépôt"
                label-n="paiements sélectionnés pour un dépôt"
              >
              </counter-label>
              <div class="total-selection" v-if="depositSelection.length">Total de {{ depositSelectionAmount }}</div>
            </div>
          </b-col>
          <b-col cols="2" class="text-right">
            <a class="btn btn-secondary" @click.prevent="selectVerifiedForDeposit()"
               v-if="depositablePayments.length"
            >
              Sélectionner si vérifié
            </a>
          </b-col>
          <b-col cols="2" class="text-right">
            <a class="btn btn-secondary btn-block" @click.prevent="selectAllForDeposit()"
               v-if="depositablePayments.length"
            >
              <span v-if="depositSelection.length === depositablePayments.length">Tout désélectionner</span>
              <span v-else>Tout sélectionner</span>
            </a>
            <div v-if="depositablePayments.length">
              <b-row>
                <b-col></b-col>
                <b-col cols="9">
                  <b-form-group label-for="depositSize" description="nb maxi de paiements">
                    <b-input class="small-input" type="number" v-model="depositSize" id="depositSize"></b-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col cols="3" class="text-right">
            <deposit-button :payments="depositSelection" @deposit="reload()" label="Créer un dépôt"></deposit-button>
          </b-col>
        </b-row>
      </div>
    </div>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="filterChanged && filterInit && !isLoading(loadingName)" class="refresh-zone">
      <b-row>
        <b-col>
          <b>Les filtres ont été modifiés. Cliquez sur "Rafraîchir" pour afficher les paiements correspondants</b>
        </b-col>
        <b-col cols="3" class="text-right">
          <a class="btn btn-block btn-primary" href @click.prevent="onRefresh">
            <i class="fa fa-filter"></i> Rafraîchir
          </a>
        </b-col>
      </b-row>
    </div>
    <div class="empty-text" v-else-if="filterInit && !isLoading(loadingName) && payments.length === 0">
      Aucun paiement correspondant
    </div>
    <div ref="excelTable" v-else>
      <table class="table table-striped" v-if="!isLoading(loadingName) && payments.length">
        <tr>
          <th>Date</th>
          <th>Date de saisie</th>
          <th>Famille</th>
          <th>Factures</th>
          <th>Analytique</th>
          <th>Mode de paiement</th>
          <th>Montant</th>
          <th class="text-right">Vérifié</th>
          <th class="text-right">Dépôt</th>
        </tr>
        <tr v-for="(payment, index) in payments" :key="payment.id">
          <td>
            <div v-if="payment.paymentDate" :title="'' + (index + 1)">
              {{ payment.paymentDate | dateToString }}
            </div>
            <div v-else>
              {{ payment.createdOn | dateToString }}
            </div>
            <span v-if="payment.deposit.id" class="badge badge-dark">déposé</span>
            <span v-if="payment.returned" class="badge badge-danger">
              {{ payment.getReturnedStatus() }}
            </span>
            <span v-if="!payment.deposit.id && !payment.returned && canChangePayment && !hideEditPayment">
              <a
                @click.prevent="editPayment(payment)"
                href
                class="show-on-hover"
                title="Modifier ou supprimer"
                v-b-tooltip
              >
                <i class="fa fa-edit"></i>
              </a>
            </span>
            <span v-if="!payment.returned && canChangePayment && !hideEditPayment">
              <a
                @click.prevent="deletePayment(payment)"
                href
                class="show-on-hover"
                title="Rendre"
                v-b-tooltip
              >
                <i class="fa fa-hand-point-left"></i>
              </a>
            </span>
          </td>
          <td>
            {{ payment.createdOn | dateToString }}
          </td>
          <td>
            <span>
              <router-link
                :to="getEntityLink(payment.entity)"
                :title="'N°' + payment.entity.id"
                v-if="!payment.entity.isOwn"
                v-b-tooltip
              >
                <span class="family-numbers">{{ payment.entity.id}}</span>
                {{ payment.entity.name }}
              </router-link>
              <div v-if="payment.emitter">Émetteur: {{ payment.emitter }}</div>
            </span>
          </td>
          <td>
            <span v-for="contribution of payment.contributions" :key="contribution.id">
              <invoice-badge :invoice="contribution.invoice"></invoice-badge>
            </span>
          </td>
          <td>
            <analytics-detail-view :analytics="payment.analytics"></analytics-detail-view>
          </td>
          <td>
            <div>
              {{ payment.paymentMode.name }} {{ payment.bankName }} {{ payment.bankNumber }}
            </div>
            <div v-if="payment.comments" class="comments">{{ payment.comments }}</div>
          </td>
          <td class="text-right number">
            {{ payment.amount|currency }}
          </td>
          <td class="text-right" :title="verifiedTitle(payment)">
            <b-checkbox
              :checked="isVerified(payment)"
              :disabled="payment.verificationDate !== null || !canChangePayment"
              :value="true"
              :unchecked-value="false"
              @change="onChangePaymentVerification(payment, $event)"
            ></b-checkbox>
          </td>
          <td class="text-right">
            <span v-if="payment.deposit.id">
              <router-link :to="{ name: 'deposit-detail', params: { depositId: payment.deposit.id, }, }">
                {{ payment.deposit.number }} {{ payment.deposit.depositOn | dateToString }}
              </router-link>
            </span>
            <span v-else>
              <b-checkbox
                :checked="isSelectedForDeposit(payment)"
                :value="true"
                :unchecked-value="false"
                @change="toggleForDeposit(payment)"
                :disabled="isPaymentDepositDisabled(payment)"
              ></b-checkbox>
            </span>
          </td>
        </tr>
      </table>
    </div>
    <div v-if="editedPayment">
      <edit-payment :payment="editedPayment" @hidden="onEditPaymentHidden" @done="onRefresh">
      </edit-payment>
    </div>
    <div v-if="deletedPayment">
      <delete-payment :payment="deletedPayment" @hidden="onDeletePaymentHidden" @done="onRefresh">
      </delete-payment>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import CounterLabel from '@/components/Controls/CounterLabel'
import LoadingGif from '@/components/Controls/LoadingGif'
import Pagination from '@/components/Controls/Pagination'
import InvoiceBadge from '@/components/Invoices/InvoiceBadge'
import AnalyticsDetailView from '@/components/Accounting/AnalyticsDetailView'
import PageHeader from '@/components/Layout/PageHeader'
import DepositButton from '@/components/Deposits/DepositButton'
import EditPayment from '@/components/Payments/EditPayment'
import DeletePayment from '@/components/Payments/DeletePayment'
import { makeChoice } from '@/types/base'
import { makePaymentDate, makePayment, makePaymentMode, makeAnalyticAccount } from '@/types/payments'
import { BackendApi, openDocument } from '@/utils/http'
import { dateToString, currency } from '@/filters/texts'
import { sum } from '@/utils/math'
import DateFrameSelector from '@/components/DateRange/DateFrameSelector'
import { distinct, existsIn } from '@/utils/arrays'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect.vue'
import { compareNumbers, compareStrings } from '@/utils/sorting'

export default {
  name: 'Payments',
  mixins: [BackendMixin],
  components: {
    CheckBoxSelect,
    DateFrameSelector,
    DeletePayment,
    EditPayment,
    DepositButton,
    AnalyticsDetailView,
    Pagination,
    CounterLabel,
    LoadingGif,
    PageHeader,
    InvoiceBadge,
  },
  data() {
    return {
      loadingName: 'payments',
      payments: [],
      paymentsCount: 0,
      page: 0,
      hasNext: false,
      hasPrevious: false,
      initialLoadingDone: false,
      paymentModes: [],
      selectedPaymentMode: makePaymentMode(),
      selectedDepositMode: makeChoice(),
      depositModes: [],
      selectedSortBy: makeChoice(),
      sortBy: [],
      verifiedPayments: new Map(),
      selectedDate: '',
      dates: [],
      depositSelection: [],
      invoicesStatus: [],
      selectedStatus: makeChoice(),
      verifiedStatus: [],
      selectedVerified: makeChoice(),
      init: false,
      editedPayment: null,
      deletedPayment: null,
      noPagination: false,
      startDate: null,
      endDate: null,
      bankNumber: '',
      entityFilter: '',
      hideEditPayment: false,
      filterChanged: false,
      filterInit: false,
      depositSize: null,
      byCreationDate: false,
      sortReversed: false,
      analytics: [],
      filterByAnalytic: false,
      analyticFilter: '',
      selectedAnalytics: [],
      returned: false,
    }
  },
  watch: {
    loading: function(newValue, oldValue) {},
    selectedPaymentMode: async function(mode) {
      await this.loadPaymentDates()
      this.onFilterChanged()
    },
    filterByAnalytic: function() {
      this.onFilterChanged()
    },
    selectedStatus: function() {
      this.onFilterChanged()
    },
    selectedVerified: function() {
      this.onFilterChanged()
    },
    selectedDepositMode: function() {
      this.onFilterChanged()
    },
    selectedSortBy: function() {
      this.sortReversed = false
      this.onFilterChanged()
    },
    byCreationDate: function() {
      this.selectedDate = ''
      this.onFilterChanged()
    },
    sortReversed: function() {
      this.onFilterChanged()
    },
  },
  computed: {
    reversedLabel() {
      if (existsIn([this.selectedSortBy.id], [1, 2, 3])) {
        return 'descendant'
      }
      return 'ascendant'
    },
    analyticChoices() {
      let analytics = this.analytics
      if (this.analyticFilter) {
        const analyticFilter = this.analyticFilter.toLowerCase()
        analytics = analytics.filter(
          elt => (
            elt.getLabel().toLowerCase().indexOf(analyticFilter) >= 0
          )
        )
      }
      return analytics
    },
    noPaginationDisabled() {
      return (
        this.isLoading(this.loadingName) ||
        ((this.pagesCount === 1 && (this.payments.length <= 100))) ||
        (this.pagesCount > 10)
      )
    },
    pagesCount() {
      if (this.noPagination) {
        return 1
      }
      return Math.ceil(this.paymentsCount / 100)
    },
    totalAmount() {
      if (this.pagesCount === 1) {
        return this.payments.reduce(
          (acc, payment) => {
            return acc + payment.amount
          },
          0
        )
      }
      return null
    },
    paginationSelector() {
      if (this.selectedPaymentMode) {
        return '' + this.selectedPaymentMode.id
      } else {
        return ''
      }
    },
    depositablePayments() {
      return this.payments.filter(elt => (elt.deposit.id === 0) && !elt.returned)
    },
    verifiablePayments() {
      return this.payments.filter(elt => !elt.isVerified)
    },
    isDepositDisabled() {
      return (this.selectedPaymentMode.id === 0)
    },
    canChangePayment() {
      return this.hasPerm('payments.change_payment')
    },
    canDeletePayment() {
      return this.hasPerm('payments.delete_payment')
    },
    verifiedAmount() {
      const verifiedPayments = Array.from(this.verifiedPayments.values())
      return currency(sum(verifiedPayments.map(elt => +elt.amount)))
    },
    depositSelectionAmount() {
      return currency(sum(this.depositSelection.map(elt => +elt.amount)))
    },
  },
  mounted() {
    this.invoicesStatus = [
      makeChoice({ id: 0, name: 'Toutes les factures', }),
      makeChoice({ id: 1, name: 'Factures à payer', }),
      makeChoice({ id: 2, name: 'Factures payées', })
    ]
    this.verifiedStatus = [
      makeChoice({ id: 0, name: 'Tous', }),
      makeChoice({ id: 1, name: 'Vérifiés', }),
      makeChoice({ id: 2, name: 'À vérifier', })
    ]
    this.depositModes = [
      makeChoice({ id: 0, name: 'Tous', }),
      makeChoice({ id: 1, name: 'Paiements déposés', }),
      makeChoice({ id: 2, name: 'Paiements à déposer', }),
      makeChoice({ id: 3, name: 'Paiements rendus', })
    ]
    this.sortBy = [
      makeChoice({ id: 0, name: 'Date', }),
      makeChoice({ id: 9, name: 'Date puis nom de famille', }),
      makeChoice({ id: 10, name: 'Date puis n° de famille', }),
      makeChoice({ id: 7, name: 'Date de saisie', }),
      makeChoice({ id: 11, name: 'Date de saisie puis nom de famille', }),
      makeChoice({ id: 12, name: 'Date de saisie puis n° de famille', }),
      makeChoice({ id: 1, name: 'Montant', }),
      makeChoice({ id: 2, name: 'Banque', }),
      makeChoice({ id: 3, name: 'Famille', }),
      makeChoice({ id: 8, name: 'N° de famille', }),
      makeChoice({ id: 4, name: 'N° de facture', }),
      makeChoice({ id: 5, name: 'Date de dépôt', }),
      makeChoice({ id: 6, name: 'Date de vérification', }),
      makeChoice({ id: 13, name: 'Émetteur', })
    ]
    this.selectedSortBy = this.sortBy[0]

    const mode = +(this.$route.query ? this.$route.query.mode : 0)
    const startDate = (this.$route.query ? this.$route.query.start_date : '')
    const endDate = (this.$route.query ? this.$route.query.end_date : '')
    const byCreationDate = !!(this.$route.query ? this.$route.query.creation_date : '')
    const sortBy = !!(this.$route.query ? this.$route.query.sort_by : '')
    const sortReversed = !!(this.$route.query ? this.$route.query.sort_reversed : '')
    const status = +(this.$route.query ? this.$route.query.status : 0)
    const depositMode = +(this.$route.query ? this.$route.query.deposit_mode : 0)
    this.bankNumber = this.$route.query ? this.$route.query.bank_number : ''
    const verified = +(this.$route.query ? this.$route.query.verified : 0)
    this.onLoaded(mode, startDate, endDate, status, depositMode, verified, byCreationDate, sortBy, sortReversed)
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    onBankNumberChanged(value) {
      this.bankNumber = value
      this.onFilterChanged()
      this.onFilterChanged()
    },
    onEntityFilterChanged(value) {
      this.entityFilter = value
      this.onFilterChanged()
    },
    async onLoaded(mode, startDate, endDate, status, depositMode, verified, byCreationDate, sortBy, sortReversed) {
      this.init = true
      if (this.$route.query) {
        this.page = +(this.$route.query.page || '1')
      } else {
        this.page = 1
      }

      let statusIndex = 0
      if (status) {
        statusIndex = this.invoicesStatus.map(elt => elt.id).indexOf(status)
        statusIndex = (statusIndex < 0) ? 0 : statusIndex
      }
      this.selectedStatus = this.invoicesStatus[statusIndex]

      let verifiedIndex = 0
      if (verified) {
        verifiedIndex = this.verifiedStatus.map(elt => elt.id).indexOf(verified)
        verifiedIndex = (verifiedIndex < 0) ? 0 : verifiedIndex
      }
      this.selectedVerified = this.verifiedStatus[verifiedIndex]

      let depositModeIndex = 0
      if (depositMode) {
        depositModeIndex = this.depositModes.map(elt => elt.id).indexOf(depositMode)
        depositModeIndex = (depositModeIndex < 0) ? 0 : depositModeIndex
      }
      this.selectedDepositMode = this.depositModes[depositModeIndex]

      this.paymentModes = [makePaymentMode({ name: 'Tous modes de paiement', })]
      await this.loadPaymentModes()
      let modeIndex = 0
      if (mode) {
        modeIndex = this.paymentModes.map(elt => elt.id).indexOf(mode)
        modeIndex = (modeIndex < 0) ? 0 : modeIndex
      }
      this.selectedPaymentMode = this.paymentModes[modeIndex]

      this.byCreationDate = byCreationDate
      await this.loadPaymentDates()
      let datesIndex = 0
      if (!this.byCreationDate) {
        if (startDate === endDate) {
          datesIndex = this.dates.map(elt => elt.value).indexOf(startDate)
          datesIndex = (datesIndex < 0) ? 0 : datesIndex
        }
      }
      this.selectedDate = this.dates[datesIndex].value
      this.startDate = startDate
      this.endDate = endDate
      // this.selectedSortBy = sortBy
      // this.sortReversed = sortReversed
      await this.reload()
      this.init = false
    },
    onPageChanged(event) {
      this.page = event.page
      if (!this.init) {
        this.reload()
      }
    },
    async reload() {
      await this.loadElements()
      if (!this.filterByAnalytic) {
        await this.loadAnalytics()
      }
      this.filterChanged = false
      this.filterInit = true
    },
    reloadPagination(value) {
      this.noPagination = value
      this.reload()
    },
    onDateFrameChanged(event) {
      this.startDate = event.startDate
      this.endDate = event.endDate
      let datesIndex = 0
      if (this.startDate === this.endDate) {
        datesIndex = this.dates.map(elt => elt.value).indexOf(this.startDate)
        datesIndex = (datesIndex < 0) ? 0 : datesIndex
      }
      this.selectedDate = this.dates[datesIndex].value
      this.onFilterChanged()
    },
    onSelectedDateChanged() {
      this.startDate = this.selectedDate
      this.endDate = this.selectedDate
    },
    onFilterChanged() {
      this.filterChanged = true
    },
    getEntityLink(entity) {
      if (entity.family) {
        return { name: 'families-detail', params: { entityId: '' + entity.id, }, }
      } else {
        return { name: 'entities-detail', params: { entityId: '' + entity.id, }, }
      }
    },
    getUrl(baseUrl) {
      let url = baseUrl + '?page=' + (this.page || 1) +
        '&mode=' + (this.selectedPaymentMode.id || 0) +
        '&start_date=' + (this.startDate || '') +
        '&end_date=' + (this.endDate || '') +
        '&status=' + (this.selectedStatus.id || 0) +
        '&deposit_mode=' + (this.selectedDepositMode.id || 0) +
        '&sort_by=' + (this.selectedSortBy.id || 0) +
        '&sort_reversed=' + (this.sortReversed ? '1' : '0') +
        '&bank_number=' + (this.bankNumber || '') +
        '&verified=' + (this.selectedVerified.id || 0) +
        '&entity=' + (this.entityFilter || '') +
        '&creation_date=' + (this.byCreationDate ? '1' : '0')
      if (this.filterByAnalytic && this.selectedAnalytics.length) {
        url += '&analytic_accounts=' + (this.selectedAnalytics.map(elt => '' + elt.id).join('-'))
      }
      if (this.noPagination) {
        url += '&no_page=1'
      }
      if (this.returned) {
        url += '&returned=1'
      }
      return url
    },
    isPaymentDepositDisabled(payment) {
      return this.isDepositDisabled || payment.returned
    },
    async loadElements() {
      this.startLoading(this.loadingName)
      let url = this.getUrl('/api/payments/')
      this.payments = []
      this.verifiedPayments = new Map()
      this.depositSelection = []
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.initialLoadingDone = true
        if (this.noPagination) {
          this.paymentsCount = resp.data.length
          this.hasNext = false
          this.hasPrevious = false
          this.payments = resp.data.map(elt => makePayment(elt))
        } else {
          this.paymentsCount = resp.data.count
          this.hasNext = !!resp.data.next
          this.hasPrevious = !!resp.data.previous
          this.payments = resp.data.results.map(elt => makePayment(elt))
        }
        this.depositSelection = []
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async loadAnalytics() {
      let url = this.getUrl('/api/payments-analytics/')
      this.analytics = []
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.analytics = resp.data.map(elt => makeAnalyticAccount(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadPaymentModes() {
      try {
        let url = '/api/payment-modes/'
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.paymentModes = this.paymentModes.concat(resp.data.map(elt => makePaymentMode(elt)))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadPaymentDates() {
      this.dates = []
      try {
        let url = '/api/payment-dates/'
        if (this.selectedPaymentMode && this.selectedPaymentMode.id) {
          url += '?paymentMode=' + this.selectedPaymentMode.id
        }
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.dates = [makePaymentDate({ value: '', label: 'Toutes les dates', })].concat(
          resp.data.map(elt => makePaymentDate({ value: dateToString(elt, 'YYYY-MM-DD'), date: elt, }))
        )
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onChangePaymentVerification(payment, value) {
      if (value) {
        this.verifiedPayments.set(payment.id, payment)
      } else {
        if (this.verifiedPayments.has(payment.id)) {
          this.verifiedPayments.delete(payment.id)
        }
      }
      this.verifiedPayments = new Map(this.verifiedPayments)
    },
    async onConfirmVerification() {
      this.startLoading(this.loadingName)
      try {
        const url = '/api/verify-payments/'
        const backendApi = new BackendApi('post', url)
        const resp = await backendApi.callApi({ payments: Array.from(this.verifiedPayments.keys()), })
        this.verifiedPayments = []
        const counter = resp.data.payments.length
        if (counter > 1) {
          await this.addSuccess('Les ' + counter + ' paiements ont été vérifiés')
        } else if (counter === 1) {
          await this.addSuccess('Un paiement a été vérifié')
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
      await this.reload()
    },
    isSelectedForDeposit(payment) {
      return this.depositSelection.indexOf(payment) >= 0
    },
    isVerified(payment) {
      return payment.isVerified || this.verifiedPayments.has(payment.id)
    },
    toggleForDeposit(payment) {
      let index = this.depositSelection.indexOf(payment)
      if (index >= 0) {
        this.depositSelection.splice(index, 1)
      } else {
        this.depositSelection.push(payment)
      }
    },
    selectAllForVerification() {
      if (this.verifiablePayments.length === this.verifiedPayments.size) {
        this.verifiedPayments = new Map()
      } else {
        const verifiedPayments = new Map()
        for (const payment of this.verifiablePayments) {
          verifiedPayments.set(payment.id, payment)
        }
        this.verifiedPayments = verifiedPayments
      }
    },
    selectVerifiedForDeposit() {
      this.depositSelection = [].concat(this.depositablePayments.filter(elt => elt.isVerified))
    },
    selectAllForDeposit() {
      if (this.depositSelection.length === this.depositablePayments.length) {
        this.depositSelection = []
      } else {
        if (this.depositSize) {
          this.depositSelection = [].concat(this.depositablePayments.slice(0, this.depositSize))
        } else {
          this.depositSelection = [].concat(this.depositablePayments)
        }
      }
    },
    editPayment(payment) {
      this.editedPayment = payment
    },
    onEditPaymentHidden() {
      this.editedPayment = null
    },
    onRefresh() {
      // refresh
      this.reload()
    },
    deletePayment(payment) {
      this.deletedPayment = payment
    },
    onDeletePaymentHidden() {
      this.deletedPayment = null
    },
    getLinks() {
      const isActive = !this.isLoading(this.loadingName) && this.payments.length
      return [
        {
          id: 1,
          label: 'Excel',
          callback: this.toExcel,
          icon: 'fa fa-file-excel',
          cssClass: isActive ? 'btn-secondary' : 'btn-secondary disabled',
        }
      ]
    },
    async toExcel() {
      const isActive = !this.isLoading(this.loadingName) && this.payments.length
      if (isActive) {
        this.hideEditPayment = true
        let that = this
        setTimeout(async function() {
          const docUrl = '/documents/table-to-excel/<key>/'
          const docSlug = 'payments-excel' + Math.round((Date.now() / 1000))
          const docContent = that.$refs.excelTable.innerHTML.toString()
          try {
            await openDocument(docUrl, docSlug, docContent)
          } catch (err) {
            await that.addError(this.getErrorText(err))
          }
          this.hideEditPayment = false
        }, 100)
      }
    },
    verifiedTitle(payment) {
      if (payment.verificationDate) {
        return 'vérifié le ' + dateToString(payment.verificationDate, 'dddd ll')
      }
      return ''
    },
    selectedAnalyticsChanged(selected) {
      this.selectedAnalytics = selected.choices
      this.onFilterChanged()
    },
  },
}
</script>

<style scoped lang="less">
.header-line {
  padding: 5px;
  color: #000;
  background: #e0e0e0;
  border-bottom: none;
}
.header-label {
  font-size: 1.2em;
  font-weight: bold;
}
tr td .show-on-hover {
  display: none;
}
tr:hover td .show-on-hover {
  display: inline-block;
  font-size: 13px;
  margin-left: 2px;
}
.filter-line {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: solid 1px #ccc;
}
.filter-block {
  color: #000;
  background: #eee;
  padding: 3px;
  margin-bottom: 5px;
}
.refresh-zone {
  margin: 20px 0;
  padding: 20px;
  background: #f8d7da;
  border-radius: 4px;
  border: solid 1px #ccc;
}
.total-selection {
  font-weight: bold;
}
</style>
